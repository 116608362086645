<template>
  <div>
    <base-header
      class="header pb-8 pt-5 pt-lg-8 d-flex align-items-center"
      style="min-height: 300px"
    >
      <!-- Mask -->
      <span class="mask bg-gradient-main opacity-8"></span>
      <!-- Header container -->
      <div class="container-fluid d-flex align-items-center">
        <div class="row w-100">
          <div class="col-lg-7 col-md-10">
            <h1 class="display-2 text-white">{{ courier.name }}</h1>
          </div>
        </div>
      </div>
    </base-header>

    <div class="container-fluid mt--7">
      <base-loader v-if="loading"></base-loader>
      <div class="row">
        <div class="col-xl-4 order-xl-2 mb-5 mb-xl-0">
          <div class="card card-profile shadow">
            <div class="row justify-content-center">
              <div class="col-lg-3 order-lg-2">
                <div class="card-profile-image">
                  <a href="#">
                    <img :src="courier.avatar" class="rounded-circle" />
                  </a>
                </div>
              </div>
            </div>
            <div
              class="card-header text-center border-0 pt-8 pt-md-4 pb-0 pb-md-4"
            >
              <div class="d-flex justify-content-between">
                <base-button
                  size="sm"
                  type="success"
                  class="mr-4"
                  v-if="courier.online"
                  >{{ $t("couriers.online") }}</base-button
                >
                <base-button size="sm" type="danger" class="mr-4" v-else>{{
                  $t("couriers.offline")
                }}</base-button>

                <base-button
                  size="sm"
                  type="danger"
                  class="float-right"
                  v-if="courier.busy"
                  >{{ $t("couriers.busy") }}</base-button
                >
                <base-button
                  size="sm"
                  type="success"
                  class="float-right"
                  v-else
                  >{{ $t("couriers.available") }}</base-button
                >
              </div>
            </div>
            <div class="card-body pt-0 pt-md-4">
              <div class="row">
                <div class="col">
                  <div
                    class="card-profile-stats d-flex justify-content-center mt-md-5"
                  >
                    <div>
                      <span class="heading">{{ courier.avgRatings }}</span>
                      <span class="description">{{
                        $t("couriers.rating")
                      }}</span>
                    </div>
                    <div>
                      <span
                        class="heading badge badge-dot"
                        v-if="courier.trusted == 'Not yet'"
                      >
                        <i class="bg-danger"></i> {{ $t("couriers.no") }}
                      </span>
                      <span class="heading badge badge-dot" v-else>
                        <i class="bg-success"></i> {{ $t("couriers.yes") }}
                      </span>
                      <span class="description">{{
                        $t("couriers.trusted")
                      }}</span>
                    </div>
                  </div>
                </div>
              </div>
              <div class="text-center">
                <h3>
                  {{ courier.name }}
                  <span class="font-weight-light">
                    , {{ "#" + courier.id }}
                  </span>
                </h3>
                <div class="h5 font-weight-300">
                  <i class="ni location_pin mr-2"></i>{{ courier.email }}
                </div>
                <div class="h5 mt-4">
                  <i class="ni business_briefcase-24 mr-2"></i
                  >{{ courier.phone }}
                </div>
                <hr class="my-4" />
              </div>
            </div>
          </div>
        </div>

        <div class="col-xl-8 order-xl-1">
          <card shadow type="secondary">
            <template v-slot:header>
              <div class="bg-white border-0">
                <div class="row align-items-center">
                  <div class="col-8">
                    <h3 class="mb-0">{{ courier.name }}</h3>
                  </div>
                  <div class="col-4 text-right">
                    <a href="#!" class="btn btn-sm btn-primary">
                      {{ $t("couriers.info") }}
                    </a>
                  </div>
                </div>
              </div>
            </template>
            <!-- National Id Image -->
            <h6 class="heading-small text-muted mb-4">
              {{ $t("couriers.national id image") }}
            </h6>
            <div class="pl-lg-4">
              <div class="row">
                <img :src="courier.national_id" class="w-100" />
              </div>
            </div>
            <hr class="my-4" />
            <!-- Passport Image -->
            <h6 class="heading-small text-muted mb-4">
              {{ $t("couriers.passport_image") }}
            </h6>
            <div class="pl-lg-4">
              <div class="row">
                <img :src="courier.passport" class="w-100" />
              </div>
            </div>
            <hr class="my-4" />
            <!-- License Image -->
            <h6 class="heading-small text-muted mb-4">
              {{ $t("couriers.license image") }}
            </h6>
            <div class="pl-lg-4">
              <div class="row">
                <img :src="courier.license_image" class="w-100" />
              </div>
            </div>
            <hr class="my-4" />
            <!-- Plate Image -->
            <h6 class="heading-small text-muted mb-4">
              {{ $t("couriers.plate image") }}
            </h6>
            <div class="pl-lg-4">
              <div class="row">
                <img :src="courier.plate_image" class="w-100" />
              </div>
            </div>
            <hr class="my-4" />
            <!-- Vehicle License Image -->
            <h6 class="heading-small text-muted mb-4">
              {{ $t("couriers.vehicle_license_image") }}
            </h6>
            <div class="pl-lg-4">
              <div class="row">
                <img :src="courier.vehiclelicence" class="w-100" />
              </div>
            </div>
            <hr class="my-4" />
            <!-- Identity Front Image -->
            <h6 class="heading-small text-muted mb-4">
              {{ $t("couriers.identity_front_image") }}
            </h6>
            <div class="pl-lg-4">
              <div class="row">
                <img :src="courier.identityfront" class="w-100" />
              </div>
            </div>
            <hr class="my-4" />
            <!-- Identity Back Image -->
            <h6 class="heading-small text-muted mb-4">
              {{ $t("couriers.identity_back_image") }}
            </h6>
            <div class="pl-lg-4">
              <div class="row">
                <img :src="courier.identityback" class="w-100" />
              </div>
            </div>
            <hr class="my-4" />
            <!-- Shipping -->
            <h6 class="heading-small text-muted mb-4">
              {{ $t("companies.shipping") }}
            </h6>
            <div class="pl-lg-4">
              <div class="row">
                <div class="col-12">
                  <div class="btn-info btn-sm">
                    {{ courier.shipping_types_name }}
                  </div>
                </div>
              </div>
            </div>
            <hr class="my-4" />
            <h6 class="heading-small text-muted mb-4">
              {{ $t("form.all_transactions") }}
            </h6>
            <div class="pl-lg-4">
              <div class="row">
                <div class="col-12 mb-3">
                  {{ $t("couriers.wallet") }}:
                  <span class="btn-success btn-sm">{{ courier.wallet }}</span>
                </div>
              </div>
              <!-- Errors Handling -->
              <div
                class="row bg-danger py-3 mb-3"
                v-if="validationErrors.length"
              >
                <div
                  class="col-md-6"
                  v-for="(error, index) in validationErrors"
                  :key="index"
                >
                  <span class="text-white mr-2 error-handling">{{
                    error
                  }}</span>
                </div>
              </div>
              <div class="row">
                <div class="col-lg-12">
                  <div class="form-group">
                    <label class="form-control-label">{{
                      $t("form.type")
                    }}</label>
                    <select
                      class="form-control form-control-alternative"
                      v-model="transType"
                      @change="getTransByType($event)"
                    >
                      <option value="all" selected>
                        {{ $t("form.all") }}
                      </option>
                      <option value="charge">
                        {{ $t("form.charge") }}
                      </option>
                      <option value="withdrawal">
                        {{ $t("form.withdrawal") }}
                      </option>
                    </select>
                  </div>
                </div>
              </div>
            </div>
            <div class="row mt-4">
              <div class="col">
                <div
                  class="card shadow"
                  :class="type === 'dark' ? 'bg-default' : 'bg-default'"
                >
                  <div
                    class="card-header border-0"
                    :class="type === 'dark' ? 'bg-default' : 'bg-default'"
                  >
                    <div class="row align-items-center">
                      <div class="col">
                        <h3
                          class="mb-0"
                          :class="type === 'dark' ? 'text-white' : 'text-white'"
                        >
                          {{ $t("tables.all_transactions") }}
                        </h3>
                      </div>
                    </div>
                  </div>

                  <div class="table-responsive" v-if="transactions.length > 0">
                    <base-table
                      class="table align-items-center table-flush"
                      :class="type === 'dark' ? 'table-dark' : 'table-dark'"
                      :thead-classes="
                        type === 'dark' ? 'thead-dark' : 'thead-dark'
                      "
                      tbody-classes="list"
                      :data="transactions"
                    >
                      <!-- Table Head -->
                      <template v-slot:columns>
                        <th>{{ $t("tables.total_transactions") }}</th>
                        <th>{{ $t("tables.total_amounts_charge") }}</th>
                        <th>{{ $t("tables.total_amounts_withdrawal") }}</th>
                        <th>{{ $t("tables.total_amounts_remain") }}</th>
                      </template>
                      <!-- Table Body -->
                      <template v-slot:default="row">
                        <td class="budget">
                          {{ row.item.total_transactons }}
                        </td>
                        <td class="budget">
                          {{ row.item.total_amounts_charge }}
                        </td>
                        <td class="budget">
                          {{ row.item.total_amounts_withdrawal }}
                        </td>
                        <td class="budget">
                          {{ row.item.total_amounts_remain }}
                        </td>
                      </template>
                    </base-table>
                  </div>
                  <div class="alert alert-danger" v-else>
                    {{ $t("tables.no_trans") }}
                  </div>
                </div>
              </div>
            </div>
            <hr class="my-4" />
            <!-- Charge Or Withdraw -->
            <h6 class="heading-small text-muted mb-4">
              {{ $t("form.charge_or_withdraw") }}
            </h6>
            <div class="pl-lg-4">
              <form @submit.prevent="submitForm">
                <!-- Errors Handling -->
                <div
                  class="row bg-danger py-3 mb-3"
                  v-if="validationErrors2.length"
                >
                  <div
                    class="col-md-6"
                    v-for="(error, index) in validationErrors2"
                    :key="index"
                  >
                    <span class="text-white mr-2 error-handling">{{
                      error
                    }}</span>
                  </div>
                </div>
                <div class="row">
                  <div class="col-lg-6">
                    <div class="form-group">
                      <label class="form-control-label">{{
                        $t("form.type")
                      }}</label>
                      <select
                        class="form-control form-control-alternative"
                        v-model="transActionType"
                      >
                        <option value="" selected>
                          {{ $t("form.choose") }}
                        </option>
                        <option value="charge">
                          {{ $t("form.charge") }}
                        </option>
                        <option value="withdrawal">
                          {{ $t("form.withdrawal") }}
                        </option>
                      </select>
                    </div>
                  </div>
                  <div class="col-lg-6">
                    <base-input
                      alternative=""
                      :label="$t('form.amount')"
                      :placeholder="$t('form.amount')"
                      input-classes="form-control-alternative"
                      v-model="amount"
                    />
                  </div>
                </div>
                <hr class="my-4" />
                <div class="col-lg-12 mt-4">
                  <button type="submit" class="btn btn-success d-block">
                    {{ $t("form.save") }}
                  </button>
                </div>
              </form>
            </div>
          </card>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import axios from "axios";
export default {
  name: "courier-profile",
  data() {
    return {
      renderComponent: false,
      loading: false,
      validationErrors: [],
      validationErrors2: [],
      courier: "",
      transType: "all",
      transactions: [],
      transActionType: "",
      amount: "",
    };
  },
  created() {
    this.getCourier();
    this.getTransByType("all");
  },
  methods: {
    getCourier: function () {
      this.loading = true;
      axios
        .get("admin/administration/couriers/" + this.$route.params.id)
        .then((response) => {
          if (response.data.error) {
            this.$swal({
              position: "center",
              icon: "error",
              title: `${this.$t("validation.faild")}`,
            });
          } else {
            this.courier = response.data.data[0];
          }
        })
        .catch(() => {
          if (!window.navigator.onLine) {
            this.$swal({
              position: "center",
              icon: "error",
              title: `${this.$t("validation.faild")}`,
              text: `${this.$t("validation.check network")}`,
            });
          }
        })
        .finally(() => (this.loading = false));
    },
    // Change Transaction Status
    getTransByType: function ($type = null) {
      let transaction_type;
      if (event && event.target.value) {
        transaction_type = event.target.value;
      } else {
        transaction_type = $type;
      }
      this.loading = true;
      this.validationErrors = [];
      axios
        .post("admin/administration/wallets/modelWalletsTransactionTotal", {
          transaction_type: transaction_type,
          courier_id: this.$route.params.id,
        })
        .then((response) => {
          if (response.data.error) {
            this.$swal({
              position: "center",
              icon: "error",
              title: `${this.$t("validation.faild")}`,
            });
          } else {
            if (response.data.status == 1) {
              this.transactions = response.data.data;
            } else {
              this.validationErrors.push(response.data.message);
            }
          }
        })
        .catch(() => {
          if (!window.navigator.onLine) {
            this.$swal({
              position: "center",
              icon: "error",
              title: `${this.$t("validation.faild")}`,
              text: `${this.$t("validation.check network")}`,
            });
          }
        })
        .finally(() => (this.loading = false));
    },
    // Charge Or Withdrawal
    validation: function () {
      this.validationErrors2 = [];
      if (this.transActionType && this.amount) {
        return true;
      } else {
        if (!this.transActionType) {
          this.validationErrors2.push(
            `${this.$t("validation.transaction required")}`
          );
        }
        if (!this.amount) {
          this.validationErrors2.push(
            `${this.$t("validation.amount required")}`
          );
        }
        return false;
      }
    },
    submitForm() {
      if (this.validation() == false) {
        this.$swal({
          position: "center",
          icon: "error",
          title: `${this.$t("validation.faild")}`,
          showConfirmButton: false,
          timer: 1200,
        });
        return false;
      }
      this.loading = true;
      this.chrg_withd();
    },
    // Charge Or Withdrawal
    chrg_withd: function () {
      this.loading = true;
      axios
        .post("admin/administration/wallets/store", {
          amount: this.amount,
          type: this.transActionType,
          courier_id: this.$route.params.id,
        })
        .then((response) => {
          if (response.data.error) {
            this.$swal({
              position: "center",
              icon: "error",
              title: `${this.$t("validation.faild")}`,
            });
          } else {
            if (response.data.status == 1) {
              this.getTransByType("all");
              if (this.transActionType == "charge") {
                this.courier.wallet = +this.courier.wallet + +this.amount;
              } else {
                this.courier.wallet = +this.courier.wallet - +this.amount;
              }
              this.$swal({
                position: "center",
                icon: "success",
                title: `${this.$t("validation.success")}`,
                text: response.data.message,
                showConfirmButton: false,
                timer: 1200,
              });
            } else {
              this.validationErrors2.push(response.data.message);
              this.$swal({
                position: "center",
                icon: "error",
                title: `${this.$t("validation.faild")}`,
                showConfirmButton: false,
                timer: 1200,
              });
            }
          }
        })
        .catch(() => {
          if (!window.navigator.onLine) {
            this.$swal({
              position: "center",
              icon: "error",
              title: `${this.$t("validation.faild")}`,
              text: `${this.$t("validation.check network")}`,
            });
          }
        })
        .finally(() => (this.loading = false));
    },
  },
};
</script>
<style lang="scss" scoped>
img {
  height: 180px;
  width: 180px;
  object-fit: contain;
}
</style>
